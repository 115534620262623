import { Config, Type } from './config';

export const environment = {
  version: '80d2504',
  production: true,
  name: 'Live',
  api: 'https://kong-internal-api.fundingasiagroup.com/internal',
  base_website_url: 'https://fsmkcommand.center',
  Base_api_url: 'https://kong-internal-api.fundingasiagroup.com',
  Auth_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/auth',
  Bond_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/v1/esbn',
  // Deprecated: use External_Endpoint instead.
  CTOS_EndPoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/ext',
  // Deprecated: use External_Endpoint instead.
  Questnet_EndPoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/ext',
  External_EndPoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/ext',
  FA_EndPoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/v2/fa',
  Parsing_service_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/dps',
  Document_EndPoint_V3:
    'https://kong-internal-api.fundingasiagroup.com/internal/api/docs_management/v3',
  Finops_Serverless_Workflows_Endpoint:
    'https://kong-internal-api.fundingasiagroup.com/internal/api/fsw',
  DocGen_EndPoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/dg',
  Finance_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/fs',
  Finance_Endpoint_V2: 'https://kong-internal-api.fundingasiagroup.com/internal/api/fs/v2',
  Finance_Pairing_Endpoint:
    'https://kong-internal-api.fundingasiagroup.com/internal/api/finance_pairing/v1',
  Member_EndPoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/ms',
  Member_EndPoint_V2: 'https://kong-internal-api.fundingasiagroup.com/internal/api/v2/members',
  Member_EndPoint_V3: 'https://kong-internal-api.fundingasiagroup.com/internal/api/v3/members',
  SOA_EndPoint_V2: 'https://kong-internal-api.fundingasiagroup.com/internal/api/v2/soa-reports',
  Loan_EndPoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/ln',
  LookUpNodeJS_EndPoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/lu/p',
  Loyalty_EndPoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/v1/loyalty',
  SMS_EndPoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/sms',
  Scheduler_EndPoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/sc',
  Logout_URI: 'https://kong-internal-api.fundingasiagroup.com/internal/api/logout?redirect=',
  Logout_Page_URL:
    'https://kong-internal-api.fundingasiagroup.com/internal/login/logout.html?home=',
  Login_URI: 'https://kong-internal-api.fundingasiagroup.com/internal/login/?redirect_uri=',
  User_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api',
  Notification_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/notification',
  Email_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/email',
  Staff_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/ss',
  Kyc_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/kyc/v1',
  CustomModule_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/cm',
  MicroLoan_Endpoint: 'https://microloan.fundingasiagroup.com',
  MicroLoan_Docs_Gen_Endpoint:
    'https://kong-internal-api.fundingasiagroup.com/internal/api/docs_gen',
  MicroLoan_Internal_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/ml',
  Underwriting_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/credit',
  Credit_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/credit/v2',
  v2_url: 'https://kong-internal-api.fundingasiagroup.com/internal/api/v2',
  Collection_Endpoint:
    'https://kong-internal-api.fundingasiagroup.com/internal/api/v1/loan-collections',
  Dispatch_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/dispatch',
  Facility_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/facility',
  Facility_Endpoint_V2: 'https://kong-internal-api.fundingasiagroup.com/internal/api/facility/v2',
  Investor_Channelling_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/ics',
  ELO_EndPoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/elo_rating',
  ELO_Rating_EndPoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/credit/v2/elo',
  Paylater_EndPoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/paylater',
  Partnership_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/partners',
  myTukar_account_Id: 819726,
  carlistbid_account_id: 833585,
  MUV_account_Id: 819776,
  carsome_account_Id: 1068468,
  ezauto_account_Id: 1207675,
  launch_darkly_client_id: '5da5726df87c9308b76af5e6',
  Bolt_Partner_EndPoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/bpp',
  Borrower_Backend_Service_Endpoint:
    'https://kong-internal-api.fundingasiagroup.com/internal/api/bbs',
  Ekyc_Esign_Service_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/ees',
  Bank_Integration_Service_Endpoint:
    'https://kong-internal-api.fundingasiagroup.com/internal/api/bis',
  scfs: 'https://old-scfs.fundingasiagroup.com',
  bowIDOrigin: 'https://apply.modalku.co.id',
  hideNonMKItem: false, // For ID Audit
  hideNonFSItem: true, // For ID Audit
  Credit_Card_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/ccs',
  Business_Account_Endpoint: 'https://kong-internal-api.fundingasiagroup.com/internal/api/bas',
  download_soa: `https://kong-internal-api.fundingasiagroup.com/internal/api/dg/v1/document`,
  type: new Config(Type.PROD),
  template_id: '9880c06e-a8a0-40b6-a1f5-a00dfeeef0a6',
  sheet_name: 'Sheet1',
  qlmProductIds: [16, 13, 193],
  enablePrivy: false,
  productIds: {
    islamicMicrofinancingMY: 195,
    islamicEcommerceMY: 196,
    boltMyCgcd: 235,
  },
  loanLevelSOA: {
    SG: {
      fs_capital: {
        template_id: 'cb1cf318-a924-4c4e-be75-2734cf1f3730',
      },
      fspl: {
        template_id: 'cb1cf318-a924-4c4e-be75-2734cf1f3730',
      },
    },
  },
  Appsmith_Product_Reminders_Url:
    'https://appsmith.fsmkcommand.center/app/elevate-internal-tools/product-repayment-reminders-671f77cf895fd059c3d99969?branch=master&embed=true&ssoTrigger=oidc',
  Appsmith_Business_Account_Lifecycle_Url:
    'https://appsmith.fsmkcommand.center/app/elevate-internal-tools/business-accounts-lifecycle-671f77cf895fd059c3d9996b?branch=master&embed=true&ssoTrigger=oidc',
  Appsmith_Business_Account_Details_Url:
    'https://appsmith.fsmkcommand.center/app/elevate-internal-tools/business-accounts-details-671f77cf895fd059c3d9996a?branch=master&embed=true&ssoTrigger=oidc',
  Appsmith_Bulk_Disbursal_Documents_Url:
    'https://appsmith.fsmkcommand.center/app/bulk-disbursal-documents-671f74bf895fd059c3d9981d?branch=master',
  Appsmith_Scheduler_Url:
    'https://appsmith.fsmkcommand.center/app/scheduler-tool/create-new-schedule-6757f0088a13cd7b1218307a',
  Appsmith_Bulk_Funding_Settings_Url:
    'https://appsmith.fsmkcommand.center/app/bulk-funding-settings-671f74bf895fd059c3d9981e?branch=master',
  Appsmith_Borrower_SOA_Url:
    'https://appsmith.fsmkcommand.center/app/borrower-soa-674d5561f260c513d3e3c104?branch=master',
  appsmithCollateralUrl:
    'https://appsmith.fsmkcommand.center/app/collateral-service-tools/home-670e8b2f97ce494475ebbc27',
  masterCustomerData: {
    individualsUrl:
      'https://appsmith.fsmkcommand.center/app/master-customer-data-ui/individuals-670cc77697ce494475ebb4a6',
    individualPageUrl:
      'https://appsmith.fsmkcommand.center/app/master-customer-data-ui/individual-page-670cc77697ce494475ebb4a3?uuid=',
    companiesUrl:
      'https://appsmith.fsmkcommand.center/app/master-customer-data-ui/companies-670cc77697ce494475ebb4a5',
    companyPageUrl:
      'https://appsmith.fsmkcommand.center/app/master-customer-data-ui/company-page-670cc77697ce494475ebb4a8?uuid=',
  },
  datadogConfiguration: {
    applicationId: '8f7172aa-f382-49c7-80ed-a31834318232',
    clientToken: 'pubb61ed877d78210599272412491422ae8',
  },
  sentryDsn:
    'https://786be81e9140ed5b2eb4a64dcd34a301@o103441.ingest.us.sentry.io/4507887833841664',
};
